<template>
  <div class="flex h-svh flex-col justify-between overflow-hidden">
    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div v-if="currentTab === 'beforeRequest'" class="z-20 flex w-full flex-col gap-4 px-6 pb-4 pt-8">
        <div class="">{{ uiTranslations.loginExpiredTitle }}</div>
        <div class="text-balance font-serif text-4xl leading-tighter">
          {{ uiTranslations.loginExpiredDescription }}
        </div>
        <div class="text-interface-250">
          <div class="flex justify-start rounded-md border border-white/50 bg-white/20 p-3 pt-4">
            <span
              class="input-wrapper relative inline-block h-full max-w-full overflow-hidden text-ellipsis py-1 leading-4">
              <span class="pointer-events-none relative inline-block whitespace-pre text-transparent">{{
                placeholder
              }}</span>
              <input
                class="!focus:outline-0 caret-brand-green-dark absolute left-0 top-0 inline !h-auto w-full min-w-2 text-ellipsis border-0 bg-transparent text-start lowercase leading-4 text-white outline-none !outline-0"
                type="text"
                v-model="inputMail"
                @input="updateChange"
                :placeholder="placeholder"
                inputMode="text"
                spellcheck="false"
                autocomplete="off"
                autofocus />
            </span>
          </div>
          <small class="block pt-2 italic">{{ uiTranslations.typeYourX }} {{ uiTranslations.emailAddress }}</small>
        </div>

        <div v-if="errorMessages" class="w-full rounded-md border border-red-500/20 bg-red-500/20 p-4 text-red-300">
          {{ errorMessages }}
        </div>

        <div class="absolute bottom-viewportBottom left-0 w-full p-6 pb-12">
          <BaseButton @click.prevent="submitRequest" :label="uiTranslations.requestNewLogin" />
        </div>
      </div>

      <div v-else class="flex h-full w-full flex-col justify-between gap-4 px-6 pb-4">
        <div>
          <p class="mb-6">{{ uiTranslations.newLoginSent }}</p>
          <div class="text-balance font-serif text-4xl leading-tighter">{{ uiTranslations.checkYourInbox }}</div>
        </div>

        <div class="flex flex-col justify-center gap-2 text-sm">
          <p class="font-semibold text-interface-50">
            {{ uiTranslations.loginSentTo }}
            <span class="mx-1 rounded-sm bg-interface-250 px-2 py-1 lowercase italic text-interface-1000">{{
              inputMail
            }}</span>
          </p>
          <p class="text-interface-150">{{ uiTranslations.isThisNotYourEmail }} {{ uiTranslations.changeEmailLong }}</p>
        </div>

        <div class="w-full pt-12">
          <BaseButton @click="currentTab = 'beforeRequest'" :label="uiTranslations.changeEmail" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import axiosHelper from "@/helpers/axiosHelper";
import { uiTranslations } from "@/helpers/languageHelpers";
import BaseButton from "@/components/BaseButton.vue";
const inputMail = ref<string>("");
const placeholder = ref<string>("Email");
const errorMessages = ref<string>("");
const currentTab = ref<string>("beforeRequest");

function updateChange(event: any) {
  placeholder.value = event.target.value;
}

function submitRequest() {
  if (!inputMail.value)
    return (errorMessages.value = `${uiTranslations.value.typeYourX} ${uiTranslations.value.emailAddress}`);

  errorMessages.value = "";
  axiosHelper.post("/auth/request-link", {
    email: inputMail.value,
    language: "hr-HR",
  });
  currentTab.value = "afterRequest";
}
</script>
