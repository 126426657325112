<template>
  <div class="flex h-full flex-col" v-if="props.formDetails">
    <BaseHeader class="mb-5">
      <template #leftIcon>
        <Transition name="fade-opacity">
          <BaseSvg name="caret-left-icon" @click="currentStep--" class="w-7 cursor-pointer" v-if="currentStep !== 1" />
        </Transition>
      </template>
    </BaseHeader>

    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div v-if="currentStep === 1" class="flex h-full w-full flex-col justify-between px-6 pt-6">
        <div class="text-center">
          <h1
            class="mx-2.5 mb-6 text-balance text-center font-serif text-4.5xl font-light leading-tighter tracking-tight">
            {{ uiTranslations.interventionStartTitle }}
          </h1>
          <p class="text-lg tracking-tight">
            {{ props.formDetails.title || uiTranslations.interventionStartDescription }}
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <BaseButton @click="currentStep = 2" :label="uiTranslations.continue"></BaseButton>
          <div @click="handleDone()" class="w-full rounded-lg py-3 text-center font-bold">
            {{ uiTranslations.skip }}
          </div>
        </div>
      </div>

      <div v-else-if="currentStep === 2" class="mb-2 flex h-full w-full flex-col justify-between px-6">
        <div
          class="flex max-h-[calc(100vh-250px)] flex-col overflow-y-auto px-2 transition-all"
          :class="isInputActive ? 'pt-0' : 'pt-20'">
          <h1
            class="w-full text-center font-serif font-light leading-tighter tracking-tight transition-all"
            :class="isInputActive ? ' mb-6 text-2xl opacity-20' : ' mb-10 text-3xl opacity-100 '">
            {{ uiTranslations.interventionFormTitle }} {{ props.formDetails.recipient || uiTranslations.someone }}.
          </h1>
          <div class="mb-3 flex w-full gap-3">
            <input
              type="text"
              :placeholder="uiTranslations.firstName"
              v-model="formData.firstName"
              @focus="isInputActive = true"
              @blur="handleFieldBlur"
              class="input-base"
              :class="hasFormError('firstName') ? 'input-error' : 'input-default'" />
            <input
              type="text"
              :placeholder="uiTranslations.lastName"
              v-model="formData.lastName"
              @focus="isInputActive = true"
              @blur="handleFieldBlur"
              class="input-base"
              :class="hasFormError('lastName') ? 'input-error' : 'input-default'" />
          </div>
          <input
            type="email"
            :placeholder="uiTranslations.emailAddress"
            v-model="formData.email"
            @focus="isInputActive = true"
            @blur="handleFieldBlur"
            class="input-base mb-3 block placeholder:capitalize"
            :class="hasFormError('email') ? 'input-error' : 'input-default'" />
          <input
            type="tel"
            :placeholder="uiTranslations.phoneNumber + ' (' + uiTranslations.optional + ')'"
            v-model="formData.phone"
            @focus="isInputActive = true"
            @blur="handleFieldBlur"
            class="input-base input-default mb-3" />
          <textarea
            :placeholder="uiTranslations.interventionPlaceholderText + ' (' + uiTranslations.optional + ')'"
            v-model="formData.openText"
            @focus="isInputActive = true"
            @blur="handleFieldBlur"
            class="input-base input-default mb-7 min-h-20"></textarea>
          <div class="mb-3">
            <input
              id="terms"
              type="checkbox"
              v-model="formData.isTermsAccepted"
              class="mr-3"
              :class="{ 'ring-2 ring-red-800': hasFormError('isTermsAccepted') }"
              @focus="isInputActive = true"
              @blur="handleFieldBlur" />
            <label for="terms" class="py-2">
              {{ uiTranslations.iAcceptThe }}
              <a :href="props.formDetails.terms_conditions_url" target="_blank" class="underline">{{
                uiTranslations.termsAndConditions
              }}</a>
            </label>
          </div>
          <ul
            v-show="Object.keys(formErrors).length"
            class="my-3 flex list-disc flex-col rounded-md border-2 border-red-800/60 bg-red-800/50 px-4 py-3 text-sm backdrop-blur-3xl transition-all">
            <TransitionGroup name="fade-opacity">
              <li v-for="(error, key) in formErrors" :key="key" class="ml-4">{{ error }}</li>
            </TransitionGroup>
          </ul>
        </div>
        <div class="mt-auto pt-4">
          <BaseButton
            @click="handleSubmit()"
            :label="uiTranslations.continue"
            :class="Object.keys(formErrors).length ? ' cursor-not-allowed opacity-50' : 'opacity-100'">
          </BaseButton>
        </div>
      </div>

      <div v-else-if="currentStep === 3" class="flex h-full w-full flex-col justify-between px-6 pt-6">
        <div class="text-center">
          <h1
            class="mx-2.5 mb-6 text-balance text-center font-serif text-4.5xl font-light leading-tighter tracking-tight">
            {{ uiTranslations.interventionDoneTitle }}
          </h1>
          <p class="text-lg tracking-tight">
            {{ uiTranslations.interventionDoneDescription }}
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <BaseButton @click="handleDone()" :label="uiTranslations.continue"></BaseButton>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import BaseButton from "@/components/BaseButton.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { uiTranslations } from "@/helpers/languageHelpers";
import { getAvailableUserLanguage } from "@/helpers/languageHelpers.ts";
import axios from "axios";
import { computed, ref } from "vue";

const props = defineProps<{
  formDetails: FormTrigger;
}>();

const emit = defineEmits(["handleCloseForm"]);
const isInputActive = ref(false);
const currentStep = ref(1);
const hasFieldBlurred = ref(false);

function handleFieldBlur() {
  isInputActive.value = false;
  hasFieldBlurred.value = true;
}

async function handleSubmit() {
  if (Object.keys(formErrors.value).length) return;
  const todayDate = new Date();
  const apiUrl = import.meta.env.VITE_MICROFORM_API_URL;

  try {
    await axios.post(
      apiUrl,
      {
        date: todayDate,
        name: formData.value.firstName + " " + formData.value.lastName,
        email: formData.value.email,
        phone: formData.value.phone,
        comment: formData.value.openText,
        question: props.formDetails.question_title,
        measurement: props.formDetails.checkin_id,
        organization_id: 1165, // TODO remove hardcoded ID (current id belongs to University of Europe)
        language: getAvailableUserLanguage(),
        emails: [props.formDetails.webhook],
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      }
    );
  } catch (error) {
    console.error("Error:", error);
  }

  currentStep.value = 3;
}

function handleDone() {
  currentStep.value = 1;
  emit("handleCloseForm");
}

const formErrors = computed(() => {
  const errors: FormErrors = {};
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!formData.value.firstName.length) errors.firstName = uiTranslations.value.pleaseEnterFirstName;
  if (!formData.value.lastName.length) errors.lastName = uiTranslations.value.pleaseEnterLastName;
  if (!emailPattern.test(formData.value.email)) errors.email = uiTranslations.value.pleaseEnterValidEmail;
  if (!formData.value.isTermsAccepted) errors.isTermsAccepted = uiTranslations.value.pleaseAcceptTC;

  return errors;
});

function hasFormError(field: keyof FormErrors) {
  if (!hasFieldBlurred.value) return;
  return formErrors.value[field];
}

const formData = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  openText: "",
  isTermsAccepted: false,
});
</script>

<style scoped>
.input-base {
  @apply w-full rounded-md  border-0 px-3 py-2 text-base ring-2 backdrop-blur-3xl transition-shadow ease-in-out placeholder:text-interface-0 placeholder:text-opacity-50 focus:border-0 focus:outline-none;
}

.input-default {
  @apply bg-interface-0/20 ring-interface-0/50 focus:ring-interface-0;
}

.input-error {
  @apply bg-red-800/20 !ring-red-800/50 focus:!ring-red-800;
}
</style>
