import { userStore } from "@/stores/userStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { lastRouteName } from "@/utils";
import { computed } from "vue";

import { currentCheckinAnswers } from "@/helpers/checkinHelpers.ts";
import router from "@/router";

export const areAllTriggeredActionsNotRelevant = computed(() => {
  if (!userStore.user?.notRelevantActions) return false;
  return triggeredActionItems.value.every((item) => userStore.user.notRelevantActions.includes(item._id));
});

export const triggeredActionItems = computed(() => {
  const allActionItems = vocabularyStore?.vocabulary?.actions;
  const checkinAnswers = currentCheckinAnswers();
  if (!allActionItems || !checkinAnswers.length) return [];

  return allActionItems.filter((actionItem) => {
    return checkinAnswers.some((answer) => {
      return actionItem.triggers.some((trigger) => {
        return trigger.questionId === answer.questionId && trigger.ratings.includes(answer.rating as number);
      });
    });
  });
});

export function handleRelevantToggle(actionId: ActionItem["_id"]) {
  if (!userStore.user?.notRelevantActions) return;
  const notRelevantActions = [...userStore.user.notRelevantActions];
  const index = notRelevantActions.indexOf(actionId);

  if (index > -1) {
    notRelevantActions.splice(index, 1);
  } else {
    notRelevantActions.push(actionId);
  }

  const updatedUser = {
    ...userStore.user,
    notRelevantActions,
  };

  userStore.updateUser(updatedUser);
  userStore.updateNotRelevantActions();

  const lastRoute = lastRouteName(router);
  router.push({ name: lastRoute ? lastRoute : "/Home" });
}

export const driverStylesMap: { [key: string]: DriverStyle } = {
  1: {
    name: "Health & Wellbeing",
    colors: {
      dark: "#103728",
      primary: "#2B8255",
      secondary: "#44B574",
      light: "#AEEBCB",
    },
    icon: "plant-icon",
  },
  2: {
    name: "Academic experience",
    colors: {
      dark: "#1A3150",
      primary: "#008CEA",
      secondary: "#6AB5FB",
      light: "#C4DAF3",
    },
    icon: "student-icon",
  },
  3: {
    name: "Transformation & Change",
    colors: {
      dark: "#432A1C",
      primary: "#AE4D0A",
      secondary: "#FBA137",
      light: "#FFCE94",
    },
    icon: "person-simple-run-icon-solid",
  },
  4: {
    name: "Engagement",
    colors: {
      dark: "#4E4419",
      primary: "#C3A31C",
      secondary: "#F0D856",
      light: "#F3ECB6",
    },
    icon: "compass-icon",
  },
  5: {
    name: "Diversity & Inclusion",
    colors: {
      dark: "#471F51",
      primary: "#9940C0",
      secondary: "#C985E5",
      light: "#E7CEF4",
    },
    icon: "hand-fist-icon-solid",
  },
};
