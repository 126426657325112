import axiosHelper from "@/helpers/axiosHelper";
import { fetchOrFallback } from "@/helpers/fetchHelper";

export const ratingColors: Array<[string, string, string, string]> = [
  ["#FE9A04", "#744702", "#2D1D04", "#140F06"], //0
  ["#FAA51E", "#744702", "#2D1D04", "#140F06"],
  ["#F6B036", "#744702", "#2D1D04", "#140F06"],

  ["#F5BC44", "#6F5622", "#302612", "#18140B"], //3
  ["#F7CA48", "#6F5622", "#302612", "#18140B"], //4
  ["#F8D74D", "#6F5622", "#302612", "#18140B"], //5

  ["#EBE255", "#7A752C", "#343215", "#18170C"], //6
  ["#DCEE5E", "#7A752C", "#343215", "#18170C"],
  ["#B3ED6A", "#7A752C", "#343215", "#18170C"], //8

  ["#6DE079", "#396E3E", "#132615", "#0D160E"],
  ["#28D387", "#396E3E", "#132615", "#0D160E"],
];

// export const ratingColors: Array<[string, string, string]> = [
//   ["#744702", "#2D1D04", "#140F06"], //0
//   ["#744D10", "#2C1F09", "#181208"],
//   ["#6F501B", "#30240D", "#18130A"],
//   ["#6F5622", "#302612", "#18140B"],
//   ["#746025", "#312911", "#17140B"],
//   ["#786926", "#2F2A12", "#17150B"], //5
//   ["#7A752C", "#343215", "#18170C"],
//   ["#6B742E", "#292D14", "#14150C"],
//   ["#516D2E", "#222C15", "#12160D"], //8
//   ["#396E3E", "#132615", "#0D160E"],
//   ["#18734A", "#0A2519", "#091710"], //10
// ];
const currentRating = ref<null | number>(null);
export const defaultRating = 5;

export function setCurrentRating(rating: number) {
  currentRating.value = rating;
}
export function getCurrentRating() {
  return currentRating.value ?? defaultRating;
}
export function hasCurrentRating() {
  return currentRating.value === null ? false : true;
}

const savedSurvey = localStorage.getItem("survey");

const initialState = savedSurvey ? JSON.parse(savedSurvey) : {};

const surveyState = reactive<Survey>(initialState);

function saveSurveyToLocalStorage(survey: Survey) {
  localStorage.setItem("survey", JSON.stringify(survey));
}

function updateSurvey(data: Survey) {
  Object.assign(surveyState, data);
}

async function fetchAndSetSurveys(segmentRevision: string) {
  const url = `${env("VITE_R2_URL")}/survey_${segmentRevision}.json`;
  const response = await fetchOrFallback<Survey>(await axiosHelper.get(url), surveyStore.survey);
  const fetchedSurvey = response.data;
  updateSurvey(fetchedSurvey);
  saveSurveyToLocalStorage(fetchedSurvey);
}

watchEffect(async () => {
  saveSurveyToLocalStorage(surveyState);
});

interface surveyStore {
  survey: Survey;
  updateSurvey: (data: Survey) => void;
  fetchAndSetSurveys: (segmentRevision: string) => Promise<void>;
}

export const surveyStore: surveyStore = {
  survey: surveyState,
  updateSurvey,
  fetchAndSetSurveys,
};
