import axiosHelper from "@/helpers/axiosHelper";
import { fetchOrFallback } from "@/helpers/fetchHelper";

const savedUser = localStorage.getItem("user");

// TODO remove initial user state, instead updateUser on first load (or on temporary checkin load)
const initialState = savedUser
  ? JSON.parse(savedUser)
  : {
      _id: "",
      segmentRevision: "",
      preferences: {
        language: "",
        allowPushNotifications: false,
        checkinLightModePreference: false,
      },
      answers: [],
      notRelevantActions: [],
      currentStepIndex: 0,
      subscriptionsCount: 0,
      magicLink: "",
      isOnboarded: false,
    };

const userState = reactive<User>(initialState);
function saveUserToLocalStorage(user: User) {
  localStorage.setItem("user", JSON.stringify(user));
}

function updateUser(newUserData: User) {
  Object.assign(userState, newUserData);
}

async function updateAnswers() {
  if (navigator.onLine) {
    if (userState?.answers?.length) {
      axiosHelper.post("/answers", { answers: userState.answers });
    }
  }
}

export async function fetchCacheFiles(user: User) {
  await vocabularyStore.fetchAndSetVocabulary(getAvailableUserLanguage(), user.segmentRevision);
  await surveyStore.fetchAndSetSurveys(user.segmentRevision);
}

export async function authenticateUser() {
  if (isEmpty(vocabularyStore.vocabulary) || isEmpty(surveyStore.survey)) {
    fetchCacheFiles(userStore.user);
  } else {
    // check if there is an update in segment revision
    const authUser = await userStore.getUser();
    if (authUser.segmentRevision !== userStore.user.segmentRevision) {
      fetchCacheFiles(authUser);
    }
    userStore.updateUser(authUser);
  }
  if (userStore?.user?._id) {
    userStore.syncUser();
  }
  console.log("authenticate done");
}

async function getUser() {
  const response = await fetchOrFallback<User>(await axiosHelper.get("/participants/me"), userStore.user);
  return response.data;
}

async function updateConfig() {
  if (navigator.onLine) {
    await axiosHelper.put("/participants/me", {
      preferences: userState.preferences,
    });
  }
}

async function updateNotRelevantActions() {
  if (navigator.onLine) {
    if (userState?.notRelevantActions?.length) {
      await axiosHelper.post("/actions/sync", {
        actions: userState.notRelevantActions,
      });
    }
  }
}

async function syncUser() {
  if (navigator.onLine) {
    Promise.all([updateAnswers(), updateConfig(), updateNotRelevantActions()]);
  }
}

watchEffect(async () => {
  saveUserToLocalStorage(userState);
});

interface UserStore {
  user: User;
  setCurrentStepIndex: (index: number) => void;
  updateUser: (newUserData: User) => void;
  updateAnswers: () => void;
  updateConfig: () => void;
  getUser: (forceFetch?: boolean) => Promise<User>;
  syncUser: () => Promise<void>;
  updateNotRelevantActions: () => void;
}

function setCurrentStepIndex(index: number) {
  userState.currentStepIndex = index;
}

export const userStore: UserStore = {
  user: userState,
  setCurrentStepIndex,
  updateConfig,
  updateUser,
  updateAnswers,
  getUser,
  syncUser,
  updateNotRelevantActions,
};
