import { userStore } from "@/stores/userStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { computed } from "vue";

import locales from "@/translations/locales.json";
import allUiTranslations from "@/translations/translations.json";

// Computed prop for user-language-change
export const uiTranslations = computed(() => {
  const availableLanguage = getAvailableUserLanguage();
  const currentLanguage = allUiTranslations[availableLanguage as keyof typeof allUiTranslations] ?? false;
  if (!currentLanguage) throw new Error(`Language "${availableLanguage}" not available in translations`);

  return currentLanguage;
});

export const availableContentLanguages = computed(() => vocabularyStore?.vocabulary?.supportedLanguages);

export function getLanguageNameFromLanguageCode(languageCode: string) {
  const languageNames: { [key: string]: string[] } = locales["language-names"];

  const inNative = languageNames[languageCode]?.[0] || languageCode;
  const inEnglish = languageNames[languageCode]?.[1] || languageCode;
  return { inNative, inEnglish };
}

export function getAvailableUserLanguage(languageCode?: string): string {
  const userLanguageCode = userStore?.user?.preferences.language;
  //TODO: check if navigator.language is available in uiTranslations

  return languageCode || userLanguageCode || "en-GB";
}

export async function setUserLanguagePreferences(languageCode: string) {
  const newLanguageCode = getAvailableUserLanguage(languageCode);

  const updatedUser = {
    ...userStore.user,
    preferences: {
      ...userStore?.user?.preferences,
      language: newLanguageCode,
    },
  };

  userStore.updateUser(updatedUser);
  userStore.updateConfig();
  vocabularyStore.fetchAndSetVocabulary(newLanguageCode, userStore.user.segmentRevision);
}
