import { compareFloats, env } from "@/utils";

/**
 * Check if the app is running as a standalone PWA.
 */
export function isStandalone(): boolean {
  if (env("MODE") === "development" && env("VITE_DEV_SKIP_STANDALONE_CHECK")) {
    return true;
  }

  return window.matchMedia("(display-mode: standalone)").matches;
}

export function getUserAgentSpecs() {
  let currentDevice: Device = "desktop";
  let currentBrowser: Browser = "unknown";
  const device = navigator.userAgent;
  const iOSVersion: string = getiOSVersion(device);

  const isIphone: boolean = device.toLowerCase().includes("iphone");
  const isAndroid: boolean = device.toLowerCase().includes("android");
  const isSafari: boolean = device.toLowerCase().includes("safari");
  const isChrome: boolean = device.toLowerCase().includes("chrome") || device.toLowerCase().includes("crios");

  if (isIphone) {
    currentDevice = "iphone";
  } else if (isAndroid) {
    currentDevice = "android";
  }

  // Chrome on mobile has both 'Chrome' and 'Safari' inside userAgent string. Safari has only 'Safari'.
  if (isChrome && isSafari) {
    currentBrowser = "chrome";
  } else if (isSafari) {
    currentBrowser = "safari";
  }

  return { currentDevice, currentBrowser, iOSVersion };
}

function getiOSVersion(userAgent: string) {
  const match = userAgent.match(/(?:iPad|iPhone|iPod)[\s]*OS[\/\s]*([\d_]+)/i);
  if (match && match[1]) {
    return match[1].replace(/_/g, ".");
  }
  return "";
}

export function checkOnboardingSupport() {
  const { currentDevice, currentBrowser, iOSVersion } = getUserAgentSpecs();

  const isBelow = (version: number) => compareFloats(parseFloat(iOSVersion), version) === -1;
  let isOsSupported = true;
  let isBrowserSupported = true;

  if (currentDevice === "iphone" && isBelow(16.4)) isOsSupported = false;
  else if (currentDevice === "iphone" && isBelow(17.4) && currentBrowser !== "safari") isBrowserSupported = false;

  return {
    isOsSupported,
    isBrowserSupported,
  };
}

export function isiOSWebView(device: Device, browser: Browser) {
  // https://github.com/milen-yordanov/detect-ios-webview/

  if (!(device === "iphone" && browser === "safari")) return false;
  if ((window as any)?.webkit?.messageHandlers) return true;

  const clientHeight = window.document?.documentElement?.clientHeight;
  const scrollHeight = window.document?.documentElement?.scrollHeight;

  return clientHeight === scrollHeight;
}
