<template>
  <RouterLink
    :to="target || ''"
    class="block min-h-12 w-full rounded-lg bg-interface-0 py-3 text-center font-bold text-interface-1000">
    {{ props.label }}
  </RouterLink>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";

const props = defineProps<{
  label: string;
  target?: RouteLocationRaw;
}>();
</script>
