<template>
  <div class="flex flex-col overflow-hidden">
    <BaseHeader>
      <template #leftIcon>
        <div class="z-50 flex items-center gap-2 pl-1" @click="isLanguageDropdownOpen = !isLanguageDropdownOpen">
          <div>
            {{ getLanguageNameFromLanguageCode(getAvailableUserLanguage()).inNative }}
          </div>
          <BaseSvg name="caret-down-icon" class="w-4" />
        </div>
      </template>

      <template #rightIcon>
        <RouterLink v-if="isStandalone()" :to="'/home'" class="p-1">
          <div class="relative">
            <BaseSvg name="x-icon" class="w-7" />
          </div>
        </RouterLink>
      </template>
    </BaseHeader>

    <div>
      <Transition name="fade-opacity" :duration="{ enter: 500, leave: 800 }">
        <div
          class="fixed inset-0 h-full w-full backdrop-blur-2xl"
          v-if="isLanguageDropdownOpen"
          @click="isLanguageDropdownOpen = false"></div>
      </Transition>

      <section class="absolute flex flex-col gap-2" v-if="isLanguageDropdownOpen">
        <div class="ml-5 rounded-xl bg-interface-500">
          <div
            v-for="languageCode in availableContentLanguages"
            @click="handleLanguageSelect(languageCode)"
            class="flex items-center justify-between border-b border-interface-350 px-4 py-2.5 shadow-sm last:border-none">
            <div class="flex flex-col gap-1 text-sm">
              <div class="font-medium">
                {{ getLanguageNameFromLanguageCode(languageCode).inNative }}
              </div>
              <div class="text-xs text-interface-250">
                {{ getLanguageNameFromLanguageCode(languageCode).inEnglish }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <main class="mt-20 flex h-full w-full flex-col items-stretch justify-between px-5">
      <section class="flex flex-col items-center gap-2.5">
        <div class="flex gap-2 text-interface-150">
          <BaseSvg name="academic-cap-icon" class="w-5" />
          <p>{{ vocabularyStore?.vocabulary?.unitName }}</p>
        </div>
        <h1
          class="text-balance text-center font-serif text-4.5xl font-light leading-tighter tracking-tight text-interface-25">
          {{ uiTranslations.welcomeToYour }}
          {{ getCheckinDurationEstimate() }}
          {{ uiTranslations.minutesShort }}
          {{ uiTranslations.checkInNoun }}
        </h1>
      </section>
      <section class="flex flex-col items-center gap-2.5 py-5 text-interface-25">
        <div class="flex">
          <BaseSvg name="check-circle-icon" class="mr-2 w-6" />
          <p>{{ participantCount }} {{ uiTranslations.xStudentsCheckedIn }}</p>
        </div>

        <div class="flex">
          <BaseSvg name="incognito-icon" class="mr-2 w-6" />
          <p>{{ uiTranslations.fullyAnonymous }}</p>
        </div>
      </section>
      <section class="mx-auto flex w-full max-w-[600px] flex-col items-center">
        <BaseButton
          :label="uiTranslations.letsStart"
          :target="
            currentAndTransferedCheckinQuestions.answered < currentAndTransferedCheckinQuestions.all
              ? '/checkin/survey'
              : '/home'
          " />
        <div class="pb-4 pt-10 text-xs text-interface-150">
          Powered by <span class="font-bold">{{ inject("providerName") }}</span>
        </div>
      </section>
    </main>
  </div>
</template>

<script setup lang="ts">
import { currentAndTransferedCheckinQuestions } from "@/helpers/checkinHelpers";
import {
  availableContentLanguages,
  getLanguageNameFromLanguageCode,
  setUserLanguagePreferences,
  uiTranslations,
} from "@/helpers/languageHelpers.ts";
import { inject, onMounted, ref } from "vue";

import BaseButton from "@/components/BaseButton.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { isStandalone } from "@/helpers/deviceHelpers";
import { getAvailableUserLanguage } from "@/helpers/languageHelpers.ts";
import { vocabularyStore } from "@/stores/vocabularyStore";

const isLanguageDropdownOpen = ref(false);

const participantCount = ref(5);

function getCheckinDurationEstimate() {
  const estimateMinutes = Math.round(0.5 * currentAndTransferedCheckinQuestions.value.all.length);
  return estimateMinutes === 0 ? 1 : estimateMinutes;
}

function countRandomly(maxSecondsToWaitBeforeIncrement: number, stopCount: number) {
  function increaseCount() {
    const randomSecondsToWaitBeforeIncrement = Math.random() * maxSecondsToWaitBeforeIncrement;
    const randomMillisecondsToWaitBeforeIncrement = randomSecondsToWaitBeforeIncrement * 1000;

    setTimeout(() => {
      if (participantCount.value !== stopCount) {
        increaseCount();
        participantCount.value++;
      }
    }, randomMillisecondsToWaitBeforeIncrement);
  }

  increaseCount();
}

function handleLanguageSelect(languageCode: string) {
  setUserLanguagePreferences(languageCode);
  isLanguageDropdownOpen.value = false;
}

onMounted(() => {
  countRandomly(5, 30);
});
</script>
