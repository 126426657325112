<template>
  <router-view v-slot="{ Component }" class="w-screen" :class="isStandalone() ? 'safe-area-inset h-lvh' : 'h-svh'">
    <Transition name="fade-opacity" mode="out-in" :duration="100">
      <div>
        <component :is="Component"></component>
        <BackgroundGradient
          v-if="route.name === 'Check-in flow' || route.name === 'Start check-in'"
          :currentComponent="Component"></BackgroundGradient>
      </div>
    </Transition>
  </router-view>
</template>

<script setup lang="ts">
import BackgroundGradient from "@/components/BackgroundGradient.vue";
import { isStandalone } from "@/helpers/deviceHelpers";
import { userStore } from "@/stores/userStore";
import { env, registerResizeEventListener } from "@/utils";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import router from "./router/";

const showUpdateSw = ref(true);

const route = useRoute();
const currentURL = ref("");
onMounted(() => {
  currentURL.value = window.location.href;
  registerResizeEventListener();
});
</script>
