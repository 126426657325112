<template>
  <Transition name="fade-opacity" mode="out-in" :duration="200">
    <main class="relative mx-auto flex h-full w-full max-w-[600px] flex-col justify-between" :key="currentStepIndex">
      <section class="mb-4 mt-20 px-6">
        <div
          :class="{
            reveal: isTitleLoading,
          }"
          @animationend="emit('resetTitleLoading')"
          :style="'animation-duration:' + titleLoadingTimeInMs + 'ms'"
          class="inline from-white from-100% to-black to-100% font-serif text-4xl font-normal leading-tight tracking-tight text-black invert">
          {{ currentQuestionVocabulary?.phrasing }}
        </div>
      </section>

      <!-- slider -->
      <section
        class="z-20 mb-12 flex w-full flex-col gap-2 px-6 transition-opacity duration-500"
        :class="{ 'opacity-20': isTitleLoading }">
        <div class="w-full">
          <div class="pointer-events-none relative flex select-none items-center justify-between px-6 pb-10">
            <div v-for="rating in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="rating" class="relative flex text-center">
              <p
                :class="getLabelStyle(rating)"
                class="absolute -ml-3 w-5 origin-bottom px-0 text-center text-5xl font-light leading-none tracking-tighter text-white transition-all duration-150">
                {{ rating }}
              </p>
            </div>
          </div>
          <div>
            <div
              class="slider-volume pointer-events-none absolute left-0 -z-10 mt-[22px] flex min-h-14 w-full items-center justify-between pl-[2.85rem] pr-[2.9rem]">
              <div
                v-for="index in 11"
                :key="index"
                :class="[sliderValue < index || isTitleLoading ? 'opacity-20' : 'opacity-50']"
                :style="[
                  isTitleLoading ? 'height: 1px' : 'height: ' + (index * 4 + 0.5) + 'px',
                  'background-color: ' + ratingColors[index - 1][0],
                ]"></div>
            </div>
            <input
              type="range"
              :value="sliderValue"
              @input="handleChange"
              min="0"
              max="10"
              step="1"
              :class="{ 'scale-90': isTitleLoading }"
              class="mt-[27px] w-full transition-all duration-500"
              :disabled="isTitleLoading" />
            <!--
@mouseup="emit('handleRatingInput', sliderValue)"
              @touchend="emit('handleRatingInput', sliderValue)"
							-->
          </div>
        </div>

        <div
          class="pointer-events-none -z-10 -mt-16 grid grid-cols-11 items-end justify-between px-2 text-xs font-normal">
          <div class="rating-bracket col-span-3" :class="[{ active: getCurrentRating() < 3 }]">Poor</div>
          <div
            class="rating-bracket col-span-3"
            :class="[{ active: getCurrentRating() > 2 && getCurrentRating() < 6 }]">
            Fair
          </div>
          <div
            class="rating-bracket col-span-3"
            :class="[{ active: getCurrentRating() > 5 && getCurrentRating() < 9 }]">
            Good
          </div>
          <div class="rating-bracket col-span-2 !border-r-0" :class="[{ active: getCurrentRating() > 8 }]">
            Excellent
          </div>
        </div>

        <div class="mx-auto mb-8 mt-14 w-full">
          <BaseButton
            class="transition-opacity duration-500"
            @mouseup="emit('handleRatingInput', sliderValue)"
            @touchend="emit('handleRatingInput', sliderValue)"
            :style="['background-color: ' + ratingColors[getCurrentRating()][0]]"
            label="Continue"
            :disabled="!hasCurrentRating()" />
        </div>
      </section>
    </main>
  </Transition>
</template>

<script setup lang="ts">
import BaseButton from "@/components/BaseButton.vue";
const props = defineProps<{
  currentStepIndex: number;
  isTitleLoading: boolean;
  currentQuestionVocabulary: VocabularyQuestion;
  titleLoadingTimeInMs: number;
  oldAnswer?: Answer;
  sliderColor?: string;
}>();

const emit = defineEmits(["resetTitleLoading", "handleRatingInput"]);

const sliderValue = ref<number>(5);

function getLabelStyle(rating: number) {
  if (props.isTitleLoading) return "font-light text-white scale-[0.3]";
  if (rating == sliderValue.value) return "font-normal mt-[3px]";
  if (firstNeighborsOfSliderValue.value.includes(rating)) return "scale-[0.5] text-white/70";
  if (secondNeighborsOfSliderValue.value.includes(rating)) return "scale-[0.4] text-white/40";
  return "font-light scale-[0.3] text-white/30";
}

const firstNeighborsOfSliderValue = computed(() => {
  const sliderNum = Number(sliderValue.value);
  return [sliderNum - 1, sliderNum + 1];
});

const secondNeighborsOfSliderValue = computed(() => {
  const sliderNum = Number(sliderValue.value);
  return [sliderNum - 2, sliderNum + 2];
});

function handleChange(e: Event) {
  console.log("New input:", (e.target as HTMLInputElement).value);
  sliderValue.value = parseInt((e.target as HTMLInputElement).value);
  updateSliderColor(ratingColors[sliderValue.value][0]);
  setCurrentRating(sliderValue.value);
}

watch(
  () => props.currentStepIndex,
  () => {
    //updateSliderColor(props.sliderColor);
    if (props.oldAnswer?.rating === undefined) {
      sliderValue.value = 5;
    } else {
      sliderValue.value = props.oldAnswer.rating;
    }
  }
);

function updateSliderColor(color?: string) {
  const svgImage = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" stroke="${color ?? "black"}" stroke-width="2"/>
  </svg>`;

  document.documentElement.style.setProperty(
    "--sliderImage",
    `url('data:image/svg+xml;utf8,${encodeURIComponent(svgImage)}')`
  );
}
</script>

<style scoped>
.reveal {
  color: hsl(0 0% 0% / 0.2);
  background-image: -webkit-linear-gradient(0deg, black 100%, black 100%);
  -webkit-background-clip: text;
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  animation: text-reveal linear forwards;
  animation-duration: 3s;
  animation-delay: 0;
}
@keyframes text-reveal {
  to {
    background-size: 100% 100%;
  }
}

.rating-bracket {
  @apply border-r-stone-600 pt-14 text-center text-white/30 transition-colors duration-500;
  border-right-width: 0.25px;

  &.active {
    @apply text-white;
  }
}

.slider-volume {
  & > div {
    transition: all 500ms ease-out;
    content: "";
    display: inline-block;
    width: 2px;
    border-radius: 100px;
    padding: 2px 0;
  }
}

/* slider thumb */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;

  & :focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-image: var(--sliderImage, url("/icon_circle.svg")), linear-gradient(90deg, #ffffff 0%, #f7f7f7 100%);
    background-repeat: no-repeat;
    background-size: 55%, 100%;
    margin-top: -2px;
    background-position: center;
    border: 2px solid white;
    border-radius: 20px;
    box-shadow: 0px 12px 30px -5px #00000066;
    height: 50px;
    width: 50px;
    box-shadow:
      0px 11px 21px -15px rgba(0, 0, 0, 0.75),
      inset 4px 4px 16px rgba(32, 44, 66, 0.05);
  }

  & ::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #d0d0d0;
    border: 6px solid white;
    height: 3rem;
    width: 3rem;
    border-radius: 20%;
  }
}
</style>
