import axiosHelper from "@/helpers/axiosHelper";
import { fetchOrFallback } from "@/helpers/fetchHelper";
import { env } from "@/utils";
import { reactive, watchEffect } from "vue";

const savedVocabulary = localStorage.getItem("vocabulary");

const initialState = savedVocabulary ? JSON.parse(savedVocabulary) : {};

const vocabularyState = reactive<Vocabulary>(initialState);

function saveVocabularyToLocalStorage(vocabulary: Vocabulary) {
  localStorage.setItem("vocabulary", JSON.stringify(vocabulary));
}

function updateVocabulary(data: Vocabulary) {
  Object.assign(vocabularyState, data);
}

async function fetchAndSetVocabulary(languageCode: string, segmentRevision: string) {
  const url = `${env("VITE_R2_URL")}/vocabulary_${segmentRevision}_${languageCode}.json`;
  const response = await fetchOrFallback<Vocabulary>(await axiosHelper.get(url), vocabularyState);
  updateVocabulary(response.data);
}

watchEffect(async () => {
  saveVocabularyToLocalStorage(vocabularyState);
});

interface VocabularyStore {
  vocabulary: Vocabulary;
  updateVocabulary: (data: Vocabulary) => void;
  fetchAndSetVocabulary: (languageCode: string, segmentRevision: string) => Promise<void>;
}

export const vocabularyStore: VocabularyStore = {
  vocabulary: vocabularyState,
  updateVocabulary,
  fetchAndSetVocabulary,
};
