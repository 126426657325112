<template>
  <div
    class="flex items-center rounded-md border border-interface-0 bg-interface-0/10 ps-4 transition-all"
    :class="{ 'border-opacity-40': !isChecked }">
    <input
      hidden
      :id="'radio-' + inputName"
      type="radio"
      :value="inputName"
      name="'onboarding-radio-button'"
      v-model="model" />
    <label :for="'radio-' + inputName" class="flex w-full items-center py-3 pr-3 text-sm font-medium">
      <div
        class="flex h-5 w-5 shrink-0 items-center justify-center rounded border border-interface-0 bg-interface-0 transition-all"
        :class="{ 'border-opacity-40 bg-opacity-0': !isChecked }">
        <BaseSvg v-show="isChecked" name="checkmark-icon" class="w-4/5 text-interface-750" />
      </div>
      <p class="ml-4 leading-relaxed">{{ inputName }}</p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import BaseSvg from "@/components/BaseSvg.vue";

const props = defineProps({
  inputName: String,
  modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);
const isChecked = computed(() => props.modelValue === props.inputName);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
