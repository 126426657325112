<template>
  <section class="flex w-full flex-col gap-4">
    <BaseProgressSteps
      :totalSteps="totalSteps"
      :transitionDuration="titleLoadingTimeInMs"
      :currentStep="currentStepIndex + 1"
      class="mx-5" />

    <div class="mx-5 flex min-h-6 flex-grow items-center justify-end transition-opacity duration-500">
      <div
        v-if="isSkipButtonShown"
        class="text-sm"
        :class="isTitleLoading ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'"
        @click="handleSkipButtonClick">
        {{ uiTranslations.skip }}
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BaseProgressSteps from "@/components/BaseProgressSteps.vue";
import { uiTranslations } from "@/helpers/languageHelpers.ts";

const props = defineProps<{
  totalSteps: number;
  isTitleLoading: boolean;
  titleLoadingTimeInMs: number;
  currentStepIndex: number;
  isSkipButtonShown: boolean;
}>();

const emit = defineEmits(["showSkipModal"]);

function handleSkipButtonClick() {
  if (props.isTitleLoading) {
    return;
  } else {
    emit("showSkipModal");
  }
}
</script>
