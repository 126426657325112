<template>
  <div class="flex h-svh flex-col items-center justify-between overflow-hidden">
    <BaseHeader class="mb-5">
      <template #leftIcon>
        <Transition name="fade-opacity">
          <BaseSvg v-if="currentStep > 1" name="caret-left-icon" @click="goBack()" class="w-7 cursor-pointer" />
        </Transition>
      </template>

      <slot>
        <BaseProgressSteps :totalSteps="6" :transitionDuration="800" :currentStep="currentStep" class="mx-2" />
      </slot>

      <template #rightIcon>
        <div class="text-sm text-interface-250">{{ currentStep }}/6</div>
      </template>
    </BaseHeader>

    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div class="z-20 mb-5 w-full px-6" :key="currentStep">
        <p class="text-balance font-serif text-3.5xl leading-tighter">
          {{ questionsMap[currentStep].title }}
        </p>
        <p class="mt-6 leading-tight text-interface-250">{{ uiTranslations.pleaseSelectOneOption }}:</p>
      </div>
    </Transition>

    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div class="flex h-full w-full flex-col justify-between overflow-scroll px-6" :key="currentStep">
        <div
          v-show="questionsMap[currentStep].isSearchable"
          class="mb-5 mt-5 flex h-12 items-center gap-4 rounded-md bg-interface-0/20 px-3">
          <BaseSvg name="search-icon" class="h-5 shrink-0 text-interface-350" />
          <input
            v-model="searchQuery"
            placeholder="Search options..."
            class="h-10 w-full border-0 bg-transparent outline-none ring-0 placeholder:text-interface-350" />
          <BaseSvg
            v-show="searchQuery"
            @click="searchQuery = ''"
            name="x-circle-icon"
            class="h-6 cursor-pointer text-interface-350" />
        </div>
        <section class="mt-5 flex h-full w-full flex-col gap-2">
          <p v-show="!filteredOptions.length" class="h-full w-full pt-20 text-center text-interface-250">
            {{ uiTranslations.noSearchResultsFor }} <span class="break-words">"{{ searchQuery }}"</span>.
          </p>
          <TransitionGroup name="fade-opacity" :duration="100">
            <SegmentationRadioButton
              v-for="option in filteredOptions"
              :key="option"
              :inputName="option"
              v-model="answers[currentStep]" />
          </TransitionGroup>
        </section>
      </div>
    </Transition>

    <div class="mt-8 w-full px-5">
      <BaseButton
        v-if="currentStep < 7"
        :label="uiTranslations.continue"
        @click="currentStep === 6 ? handleSegmentationEnd() : goForward()"
        :class="{ 'pointer-events-none bg-interface-250 text-opacity-20': isButtonDisabled }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { uiTranslations } from "@/helpers/languageHelpers.ts";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import BaseProgressSteps from "@/components/BaseProgressSteps.vue";
import BaseButton from "@/components/BaseButton.vue";
import SegmentationRadioButton from "@/components/SegmentationRadioButton.vue";
import { useRouter, useRoute } from "vue-router";
import { userStore } from "@/stores/userStore";

const router = useRouter();
const route = useRoute();

const currentStep = ref(1);
const searchQuery = ref("");
const answers = ref<{ [key: number]: string }>({});

const allCountries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bangladesh",
  "Barbados",
  "Bahamas",
  "Bahrain",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burma",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo-Brazzaville",
  "Congo-Kinshasa",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "El Salvador",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Federated States of Micronesia",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Lands",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and McDonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent",
  "Samoa",
  "San Marino",
  "São Tomé and Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Swaziland",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Vietnam",
  "Venezuela",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Other",
];

function handleSegmentationEnd() {
  const segmentation = {};
  for (const key in questionsMap) {
    segmentation[questionsMap[key].key] = answers.value[key];
  }

  userStore.updateUser({ ...userStore.user, segmentation });
  // TODO: send values to backend
  answers.value = {};
  router.push("/home");
}

const filteredOptions = computed(() => {
  if (!searchQuery.value) {
    return questionsMap[currentStep.value].options;
  }
  return questionsMap[currentStep.value].options.filter((option) =>
    option.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// TODO: update hardcoded options & extract countries array to appropriate location
const questionsMap: Record<number, { title: string; isSearchable: boolean; options: string[] }> = {
  1: {
    title: "What's your programme?",
    key: "programme",
    isSearchable: true,
    options: [
      "Computer Science",
      "Mechanical Engineering",
      "Business Administration",
      "Psychology",
      "Biology",
      "Electrical Engineering",
      "Economics",
      "Political Science",
      "Sociology",
      "Environmental Science",
    ],
  },
  2: {
    title: "What was your country of residence, prior to attending University of Europe?",
    isSearchable: true,
    key: "residency",
    options: allCountries,
  },
  3: {
    title: "What's your gender?",
    isSearchable: false,
    key: "gender",
    options: ["Male", "Female", "Other"],
  },
  4: {
    title: "What's your campus?",
    isSearchable: false,
    key: "campus",
    options: ["Berlin", "Hamburg", "Iserlohn", "Potsdam", "Dubai"],
  },
  5: {
    title: "What semester are you in?",
    isSearchable: false,
    key: "semester",
    options: ["1st Semester", "2nd Semester", "Other"],
  },
  6: {
    title: "What's your form of study this semester?",
    isSearchable: false,
    key: "study_type",
    options: ["On campus", "Online"],
  },
};

const isButtonDisabled = computed(() => {
  return !answers.value[currentStep.value];
});

function goForward() {
  if (currentStep.value > 5) return;
  searchQuery.value = "";
  currentStep.value++;
}

function goBack() {
  if (currentStep.value < 2) return;
  searchQuery.value = "";
  currentStep.value--;
}
</script>
