<template>
  <div class="flex flex-col justify-between overflow-hidden h-svh">
    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div>
        <section class="z-20 flex flex-col w-full gap-8 px-6 pb-4">
          <div class="">{{ organization.name }}</div>
          <div class="font-serif text-4xl text-balance leading-tighter">Test your Microsoft SSO account</div>
        </section>
        <section class="z-20 flex flex-col w-full gap-8 px-6 pb-4">
          <template v-if="user && user.displayName">
            <div>
              Currently authenticated as {{ user.displayName }} <span class="text-sm">{{ user.email }}</span>
              <div>
                <pre>{{ user }}</pre>
              </div>
              <BaseButton class="mt-8" @click="logout" label="Logout" />
            </div>
            <BaseButton class="mt-8" @click="listUsers" label="List users" />
            <pre>{{ users }}</pre>
          </template>
          <template v-else>
            <BaseButton class="mt-8" @click="login" label="Login" />
          </template>
        </section>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import BaseButton from "@/components/BaseButton.vue";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { Client } from "@microsoft/microsoft-graph-client";
const clientId = import.meta.env.VITE_MSAL_CLIENT_ID;
const authority = `https://login.microsoftonline.com/organizations`;
console.log(clientId, authority);
const msalInstance = new PublicClientApplication({
  auth: {
    clientId,
    authority,
  },
  cache: {
    cacheLocation: "localStorage",
  },
});

const user = ref({});
const users = ref([]);
const account = ref({});
const graphClient = ref<Client | null>();
const organization = {
  name: "GUS",
};

async function login() {
  await msalInstance.handleRedirectPromise();
  const response = await msalInstance.acquireTokenPopup({
    scopes: [
      "User.Read",
      "profile",
      "openid",
      "email",
      "offline_access",
      "User.Read.All",
      "CustomAuthenticationExtension.Read.All",
      "CustomSecAttributeAssignment.Read.All",
    ],
  });
  account.value = response.account;
  await msalInstance.setActiveAccount(response.account);
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
    account: response.account,
    interactionType: InteractionType.Popup,
    scopes: [
      "User.Read",
      "profile",
      "openid",
      "email",
      "offline_access",
      "User.Read.All",
      "CustomAuthenticationExtension.Read.All",
      "CustomSecAttributeAssignment.Read.All",
    ],
  });

  graphClient.value = Client.initWithMiddleware({ authProvider: authProvider });
  window.location.reload();
}

async function logout() {
  await msalInstance.logout();
  user.value = {};
}

async function listUsers() {
  const u = await graphClient.value.api("/users").get();
  users.value = u;
}

onMounted(async () => {
  await msalInstance.initialize();
  const acc = await msalInstance.getActiveAccount();
  if (!acc) return;
  account.value = acc;
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
    account: acc,
    interactionType: InteractionType.Popup,
    scopes: [
      "User.Read",
      "profile",
      "openid",
      "email",
      "offline_access",
      "User.Read.All",
      "CustomAuthenticationExtension.Read.All",
      "CustomSecAttributeAssignment.Read.All",
    ],
  });

  graphClient.value = Client.initWithMiddleware({ authProvider: authProvider });
  const userResponse = await graphClient.value
    .api("/me")
    .select("id")
    .select("displayName")
    .select("mail")
    .select("customSecurityAttributes")
    .get();
  user.value = userResponse;
});
</script>
