<template>
  <div v-if="isLoading" class="animate-pulse text-center">
    {{ uiTranslations.loading }}
  </div>

  <div v-else class="flex flex-col">
    <BaseHeader />

    <main
      class="mt-5 flex h-full w-full flex-col items-center overflow-y-auto overflow-x-hidden px-5"
      :class="{
        'justify-between': !isFinished || (isFinished && !isFinishAnimationActive && triggeredActionItems.length),
      }">
      <h1 class="mx-2.5 mb-5 text-balance text-center font-serif text-3xl font-light leading-8 tracking-tight">
        {{ getHomeViewProperties().title }}
      </h1>

      <div
        v-if="getHomeViewProperties().isProgressCircleShown"
        class="relative flex w-full max-w-md items-center"
        :class="
          isFinishAnimationActive
            ? 'finished-container-lock pointer-events-none'
            : isFinished
              ? 'pointer-events-none h-[100px]'
              : ''
        ">
        <RouterLink :to="getHomeViewProperties().buttonTarget" class="aspect-square w-full rounded-full">
          <div
            class="pointer-events-none absolute left-1/2 top-1/2 flex aspect-square w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full">
            <ProgressCircle
              :colors="driverColors"
              :answerCount="currentAndTransferedCheckinQuestions.answered.length"
              :isFillAnimated="!(isFinished && !isFinishAnimationActive)"
              :type="getProgressCircleType()"
              :class="isFinishAnimationActive ? 'finished-circle-lock' : isFinished ? '!w-16' : ''"></ProgressCircle>
          </div>

          <div
            class="relative left-1/2 top-1/2 flex aspect-square w-full -translate-x-1/2 flex-col items-center justify-center rounded-full"
            :class="getUserAgentSpecs().currentBrowser === 'safari' ? '' : '-translate-y-1/2'">
            <div class="relative h-14 w-14">
              <div
                v-if="!isFinished"
                class="absolute inset-0 h-full w-full animate-ping-sm rounded-full bg-interface-25"></div>
              <div class="relative inset-0 flex h-full w-full items-center justify-center rounded-full bg-interface-25">
                <BaseSvg v-if="isFinished" name="checkmark-icon" class="w-8 text-interface-1000" />
                <BaseSvg v-else-if="isStarted" name="play-icon" class="w-8 text-interface-1000" />
                <BaseSvg v-else name="plus-icon" class="w-8 text-interface-1000" />
              </div>
            </div>

            <div v-if="!isFinished" class="-mb-5 mt-5 min-h-5 max-w-[80%] text-center text-sm">
              {{ isStarted ? uiTranslations.resumeCheckinShort : uiTranslations.startCheckinShort }}
            </div>
          </div>
        </RouterLink>
      </div>

      <BaseButton
        v-if="getHomeViewProperties().isButtonShown"
        :label="getHomeViewProperties().buttonLabel"
        :target="getHomeViewProperties().buttonTarget"
        class="my-6" />
    </main>
  </div>
</template>

<script setup lang="ts">
import {
  currentAndTransferedCheckinQuestions,
  currentCheckin,
  currentCheckinStatus,
  getComingSoonTitle,
} from "@/helpers/checkinHelpers.ts";

import BaseButton from "@/components/BaseButton.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { areAllTriggeredActionsNotRelevant, driverStylesMap, triggeredActionItems } from "@/helpers/actionHelpers.ts";
import { getUserAgentSpecs, isStandalone } from "@/helpers/deviceHelpers";
import { getAvailableUserLanguage, uiTranslations } from "@/helpers/languageHelpers.ts";

const router = useRouter();

const { isStarted, isEndingSoon, isFinished } = currentCheckinStatus();

const isLoading = ref(false);
const isFinishAnimationActive = ref(false);
const hasUserAnswers = userStore?.user?.answers?.length;

function getHomeViewProperties() {
  const {
    lastChanceTitle,
    beforeStartTitle,
    startCheckinLong,
    welcomeBackTitle,
    resumeCheckinLong,
    afterDoneTitle,
    showAllActionItems,
    noCheckinTitle,
  } = uiTranslations.value;

  let title = "";
  let buttonTarget = "";
  let buttonLabel = "";
  let isButtonShown = true;
  let isProgressCircleShown = true;

  if (!currentCheckin.value && !hasUserAnswers)
    return {
      title: noCheckinTitle,
      buttonTarget,
      buttonLabel,
      isButtonShown: false,
      isProgressCircleShown: false,
    };

  if (!isStarted) {
    title = isEndingSoon ? lastChanceTitle : beforeStartTitle;
    buttonTarget = "/checkin/start";
    buttonLabel = startCheckinLong;
  } else if (!isFinished) {
    title = isEndingSoon ? lastChanceTitle : welcomeBackTitle;
    buttonTarget = "checkin/survey";
    buttonLabel = resumeCheckinLong;
  } else {
    const comingSoonTitle = getComingSoonTitle();
    title = afterDoneTitle + (comingSoonTitle !== undefined ? " " + comingSoonTitle + "." : "");
    buttonTarget = triggeredActionItems.value.length ? "/actions" : "";
    buttonLabel = triggeredActionItems.value.length ? showAllActionItems : "";
    isButtonShown = Boolean(triggeredActionItems.value.length && !isFinishAnimationActive.value && isStandalone());
  }

  return {
    title,
    buttonTarget,
    buttonLabel,
    isButtonShown,
    isProgressCircleShown,
  };
}

const driverColors = computed(() => {
  if (userStore?.user?.preferences.checkinLightModePreference)
    return currentAndTransferedCheckinQuestions.value.all.map(
      (question: any) => driverStylesMap[question.driverId].colors.secondary
    );

  return currentAndTransferedCheckinQuestions.value.all.map(
    (question: any) => driverStylesMap?.[question.driverId]?.colors?.primary ?? "#2B8255"
  );
});

function handleFinishLoading() {
  isFinishAnimationActive.value = true;
  setTimeout(() => {
    isFinishAnimationActive.value = false;
  }, 5000);
}

async function fetchCacheFiles(user: User) {
  isLoading.value = true;
  await vocabularyStore.fetchAndSetVocabulary(getAvailableUserLanguage(), user.segmentRevision);
  await surveyStore.fetchAndSetSurveys(user.segmentRevision);
}

function getProgressCircleType() {
  const noAnswers = currentAndTransferedCheckinQuestions.value.answered.length === 0;
  return noAnswers ? "spinner" : "progress";
}

onMounted(async () => {
  if (lastRouteName(router) === "Check-in flow" && isFinished) {
    handleFinishLoading();
  }
  if (isEmpty(vocabularyStore.vocabulary) || isEmpty(surveyStore.survey)) {
    isLoading.value = true;
    fetchCacheFiles(userStore.user);
  } else {
    // check if there is an update in segment revision
    const authUser = await userStore.getUser();
    if (authUser.segmentRevision !== userStore.user.segmentRevision) {
      isLoading.value = true;
      fetchCacheFiles(authUser);
    }
    userStore.updateUser(authUser);
  }
  isLoading.value = false;
  if (userStore?.user?._id) {
    console.log("sync user");
    userStore.syncUser();
  }
});
</script>

<style scoped>
.finished-circle-lock {
  animation: spin-shrink 5s linear forwards;
}

@keyframes spin-shrink {
  0% {
    transform: rotate(0deg);
    width: 100%;
  }
  50% {
    transform: rotate(360deg);
    width: 100%;
  }
  80% {
    width: 70px;
    transform: rotate(1600deg);
  }
  100% {
    transform: rotate(1690deg);
    width: 64px;
  }
}

.finished-container-lock {
  animation: height-shrink 5s ease-in-out forwards;
}

@keyframes height-shrink {
  0%,
  50% {
    height: 100%;
  }
  80% {
    height: 80%;
  }
  100% {
    height: 100px;
  }
}
</style>
