import axiosHelper from "@/helpers/axiosHelper";
import { userStore } from "@/stores/userStore";
import ActionItemView from "@/views/ActionItemView.vue";
import ActionsView from "@/views/ActionsView.vue";
import AuthenticateMagiclink from "@/views/AuthenticateMagiclink.vue";
import CheckinFlowView from "@/views/CheckinFlowView.vue";
import CheckinStartView from "@/views/CheckinStartView.vue";
import HomeView from "@/views/HomeView.vue";
import OnboardingSignup from "@/views/OnboardingSignup.vue";
import SegmentationView from "@/views/SegmentationView.vue";
import CheckinAuthenticateView from "@/views/CheckinAuthenticateView.vue";
import RequestAuthLinkView from "@/views/RequestAuthLinkView.vue";
import MSAuthView from "@/views/MSAuthView.vue";
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/magiclink/:hash",
    name: "MagicLink",
    component: AuthenticateMagiclink,
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/onboarding",
    children: [
      {
        path: "segmentation",
        props: true,
        name: "Segmentation view",
        component: SegmentationView,
      },
      {
        path: ":hostname",
        name: "Onboarding",
        component: OnboardingSignup,
      },
      {
        path: ":hostname/done",
        name: "Onboarding complete",
        component: OnboardingSignup,
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "web",
        name: "Authenticate",
        component: CheckinAuthenticateView,
      },
      {
        path: "request",
        name: "Request link",
        component: RequestAuthLinkView,
      },
    ],
  },
  {
    path: "/checkin",
    children: [
      {
        path: "start",
        name: "Start check-in",
        meta: {
          requireAuth: true,
        },
        component: CheckinStartView,
      },
      {
        path: "survey",
        name: "Check-in flow",
        component: CheckinFlowView,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

if (["staging", "local"].includes(import.meta.env.VITE_ENVIRONMENT)) {
  routes.push(
    {
      path: "/internal-auth/:name",
      name: "Internal Auth",
      // @ts-expect-error - TS complains about the beforeEnter function not being a NavigationGuard
      beforeEnter: (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
        axiosHelper.get("/auth/internal?email=" + to.params.name + "@studentpulse.io").then((response) => {
          if (response.data?.success) {
            userStore.updateUser(response.data.data);
            next("/checkin/start");
          } else {
            next();
          }
        });
      },
    },
    {
      path: "/ms-auth",
      name: "MS Auth",
      component: MSAuthView,
    }
  );
}
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from) => {
  if (to.meta.requireAuth !== false) {
    console.log("REQUIRE AUTH...");
    // await authenticateUser();
  }
});

export default router;
